import { Dispatch, SetStateAction, useEffect } from 'react'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { Button } from 'components/button'
import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'
import Image from 'next/image'

type HomeProps = {
  connector: WalletConnect
  setConnector: Dispatch<SetStateAction<WalletConnect | null>>
  setAccount: Dispatch<SetStateAction<string | null>>
}

const Home: NextPage<HomeProps> = ({ connector, setConnector, setAccount }) => {
  const router = useRouter()

  const handleConnect = async () => {
    const connector = new WalletConnect({
      bridge: 'https://bridge.walletconnect.org', // Required
    })
    setConnector(connector)
    // Check if connection is already established
    if (!connector.connected) {
      // create new session
      await connector.createSession().then(() => {
        const uri = connector.uri
        QRCodeModal.open(uri, () => console.log(uri), {
          registryUrl: '/registry.json',
        })
      })
    }
  }

  useEffect(() => {
    if (connector) {
      // Subscribe to connection events
      connector.on('connect', (error, payload) => {
        if (error) {
          throw error
        }
        // Get provided accounts and chainId
        const { accounts } = payload.params[0]
        setAccount(accounts[0])
        QRCodeModal.close()
        router.push('/connected')
      })

      connector.on('session_update', (error, payload) => {
        if (error) {
          throw error
        }
        // Get updated accounts and chainId
        const { accounts } = payload.params[0]
        setAccount(accounts[0])
        router.push('/connected')
      })

      connector.on('disconnect', (error) => {
        if (error) {
          throw error
        }

        router.push('/')
        setAccount(null)
        // Delete connector
        setConnector(null)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connector])

  return (
    <>
      <h1 className="animate">Welcome</h1>
      <p className="content animate">
        {
          'You are on your way to becoming a collector of NFTs. Connect with the Sidekick companion app to get started.'
        }
      </p>

      <p>
        This is for people who want to dip their toes into the crypto universe
        and experience minting an NFT and viewing it in your wallet.
      </p>
      <p className="content animate">
        Click below to install the Sidekick companion app from your preferred
        store.
      </p>
      <div className="btn-wrapper">
        <Image
          src="/app-store-badge.svg"
          width={119.6}
          height={40}
          alt="download Sidekick by NCS on the App Store"
        />
        <Image
          src="/google-play-badge.png"
          height={58}
          width={138}
          alt="download Sidekick by NCS on the App Store"
        />
      </div>
      <p>
        <strong>
          This app is for educational purposes and is not meant to be your
          primary crypto wallet.
        </strong>
      </p>
      <p>
        By connecting with Sidekcik, you allow this website to view your &nbsp;
        <strong>public</strong>&nbsp;address.
      </p>

      <Button onClick={handleConnect}>Connect with Sidekick</Button>
      <p>
        Never share your <strong>private</strong> key
      </p>
    </>
  )
}

export default Home
