import { WithChildren } from 'types/with-children'
import styles from './button.module.scss'

type ButtonProps = WithChildren<{
  onClick?: () => void
  className?: string
  disabled?: boolean
}>

export const Button = ({
  onClick,
  className,
  disabled,
  children,
}: ButtonProps) => {
  const classes = className ? `${styles.button} ${className}` : styles.button
  return (
    <button onClick={onClick} className={classes} disabled={disabled}>
      {children}
    </button>
  )
}
